import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ContactBody } from "../components/contact-body"

const ContactPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Contact 1st Call Junk Haul Sacramento - Sacramento Junk Removal & Junk Hauling Services"
                description="Contact 1st Call Junk Haul Sacramento to help with your Sacramento Junk Hauling, Junk Removal, Dirt and Rock Removal, Green Waste Removal, Property Cleanout, and Commercial Services. If you have any
                questions, need advice, or need some clarification, 1st Call Junk Haul Sacramento is here to help you."
                location={props.location.pathname}
            />
            <div>
                <ContactBody />
            </div>
        </Layout>
    )
}
export default ContactPage