import React, { Component } from "react"
import { ContactForm } from "../components/contact-form"
// import { StaticImage } from "gatsby-plugin-image"

export class ContactBody extends Component {
    render() {
        return (
            <div className="bg-bggray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-bold text-2xl">Contact 1st Call Junk Haul Sacramento</h1>
                            <p className="py-2">Have questions regarding Professional Sacramento Junk Haul & Junk Removal services?</p>
                            <p>Contact us today and recieve a same day reply! We love helping people reach their goals.</p>

                            <p className="py-2 font-bold">If you have questions about pricing, the logistics of the job or anything else regarding the following services:</p>

                            <ul className="list-disc lg:ml-5">
                                <li>Sacramento Junk Haul services</li>
                                <li>Sacramento Junk Removal services</li>
                                <li>Sacramento Dirt & Rock Removal services</li>
                                <li>Sacramento Green Waste Removal services</li>
                                <li>Sacramento Property Cleanout services</li>
                                <li>Sacramento Commercial services</li>
                            </ul>

                            <p className="py-2">Feel Free to <a className="text-green hover:opacity-70" href="tel:916-591-3803" title="Call 1st Call Junk Haul Sacramento" alt="Call 1st Call Junk Haul Sacramento">Call</a>, Text, or <a href="mailto:Michael@1stcalljunkhaulsac.com" title="Email 1st Call Junk Haul Sacramento" alt="Email 1st Call Junk Haul Sacramento" className="text-green hover:opacity-70">E-mail</a> us anytime! We’ll get back to you within the same day. If you need us quicker, make sure to call us!</p>
                            <p className="py-2">Customer satisfaction is our main priority and we strive to deliver the best Junk Haul & Junk Removal services in the Greater Sacramento Area.</p>
                            <p>We look forward to hearing from you!</p>
                        </div>

                        <div className="mx-auto pt-8 pb-1">
                            <div className="flex justify-center">
                                <ContactForm formName="Contact Page" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}